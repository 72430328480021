import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "../Header";
import { grey } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003057",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
          "&:hover": {
            color: "white",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: grey[300],
          color: grey[900],
          fontSize: "1em",
        },
      },
    },
  },
});

function Copyright() {
  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="body2" color="text.secondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://highlandhomes.com/">
          Highland Homes
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Box>
  );
}

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Header />
        <Container
          component="main"
          maxWidth={false}
          sx={{
            flexGrow: 1,
            pt: 8,
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            height: "calc(100% - 64px)", // Subtract the header height (adjust if necessary)
            position: "relative",
          }}
        >
          {children}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
