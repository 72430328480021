import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import Layout from "./components/Layout";
import "./App.css";
import Home from "./pages/Create";
import { Container, Box } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import { SignInButton } from "./components/Buttons/SignInButton";
import { Route, Routes } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import History from "./pages/History";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Edit from "./pages/Edit";
import View from "./pages/View";
import Login from "./pages/Login";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY as string);

const loginRequest = {
  scopes: ["user.Read", "profile", "offline_access"],
};

const AuthCreate: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <Home />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Container sx={{ mt: (theme) => theme.spacing(5) }}>
          <Box mx={2}>
            <h5 className="card-title">Please sign-in to access.</h5>
          </Box>
          <SignInButton variant="contained" />
        </Container>
      </UnauthenticatedTemplate>
    </div>
  );
};

const AuthHistory: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <History />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Container sx={{ mt: (theme) => theme.spacing(5) }}>
          <Box mx={2}>
            <h5 className="card-title">Please sign-in to access.</h5>
          </Box>
          <SignInButton variant="contained" />
        </Container>
      </UnauthenticatedTemplate>
    </div>
  );
};

const AuthEdit: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <Edit />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Container sx={{ mt: (theme) => theme.spacing(5) }}>
          <Box mx={2}>
            <h5 className="card-title">Please sign-in to access.</h5>
          </Box>
          <SignInButton variant="contained" />
        </Container>
      </UnauthenticatedTemplate>
    </div>
  );
};

const AuthView: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <View />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Container sx={{ mt: (theme) => theme.spacing(5) }}>
          <Box mx={2}>
            <h5 className="card-title">Please sign-in to access.</h5>
          </Box>
          <SignInButton variant="contained" />
        </Container>
      </UnauthenticatedTemplate>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Layout>
        <Routes>
          <Route path="/" element={<AuthHistory />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create" element={<AuthCreate />} />
          <Route path="/edit/:id" element={<AuthEdit />} />
          <Route path="/view/:id" element={<AuthView />} />
        </Routes>
      </Layout>
    </LocalizationProvider>
  );
};

export default App;
