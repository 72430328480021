import React, { useReducer, createContext } from "react";

export interface ChangeOrderAddressTypes {
  id: string;
  address: string;
  buyerName: string;
  baseSalesPrice: number;
  totalSubmittedCoValue: number;
  community: string;
  currentSalesPrice: number;
  totalCo: number;
  pendingCo: number;
}

export interface ChangeOrderSelectedAddressTypes {
  id: string;
  date: Date;
  submitted: Date;
  notes: string;
  beforeChandgeOrderPrice: number;
  changeOrderNumber: number;
  changeOrderPrice: number;
  changeOrderType: string;
  changeOrderNewPrice: number;
  status: string;
  buyerName: string;
}

interface ChangeOrder {
  address: ChangeOrderAddressTypes[] | [];
  selectedAddress: ChangeOrderSelectedAddressTypes[] | [];
  selectedCO: any;
}
export type UIState = {
  navbar: {
    title: string;
  };
  snackbar: {
    show: boolean;
    message: string;
    severity: "error" | "warning" | "info" | "success";
  };
  formState: {
    subDivision: string;
    customerBuyer: string;
    address: string;
    planOverride: boolean;
    overrideSubdivision: string;
    plan: string;
    elevation: string;
  };
  changeOrder: ChangeOrder;
};
const initialState: UIState = {
  changeOrder: {
    address: [],
    selectedAddress: [],
    selectedCO: {},
  },
  navbar: {
    title: "Transaction Tracking",
  },
  snackbar: {
    show: false,
    message: "An Error Happened",
    severity: "error",
  },
  formState: {
    subDivision: "",
    customerBuyer: "",
    address: "",
    planOverride: false,
    overrideSubdivision: "",
    plan: "",
    elevation: "",
  },
};

export const UIContext: any = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "ChangeOrderSelected":
      return {
        ...state,
        changeOrder: { ...state.changeOrder, selectedCO: action.payload },
      };
    case "ChangeOrderSelectedAddress":
      return {
        ...state,
        changeOrder: { ...state.changeOrder, selectedAddress: action.payload },
      };
    case "ChangeOrderAddress":
      return {
        ...state,
        changeOrder: { ...state.changeOrder, address: action.payload },
      };
    case "Snackbar":
      return {
        ...state,
        snackbar: action.payload,
      };
    case "Formstate":
      return {
        ...state,
        formState: action.payload,
      };
    case "Navbar":
      return {
        ...state,
        navbar: action.payload,
      };
    default:
      return state;
  }
};

export const UIContextProvider = (props: any) => {
  const [state, dispatch]: any = useReducer(reducer, initialState);

  return (
    <UIContext.Provider value={[state, dispatch]}>
      {props.children}
    </UIContext.Provider>
  );
};
