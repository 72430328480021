import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { UIContextProvider } from "./providers/UIProvider";
import App from "./App";
import "./index.css";
import SnackbarAlert from "./components/snackbarAlert";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Initialize MSAL instance
export const msalInstance = new PublicClientApplication(
  msalConfig as Configuration
);

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <UIContextProvider>
          <SnackbarAlert />
          <App />
        </UIContextProvider>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
);
