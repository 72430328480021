import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
} from "@mui/x-data-grid-pro";
import {
  Stack,
  Tooltip,
  IconButton,
  Button,
  FormControl,
  InputAdornment,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import NoRows from "../../components/DataGrid/animations/NoRows";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Header from "../../components/Header";
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

type FilterItem = {
  id?: string | number;
  field: string;
  operator: string;
  value: string | string[]; // Allow value to be string or array of strings
};

type Filters = {
  items: FilterItem[];
  quickFilterValues: string[];
};

const defaultFilter: Filters = {
  items: [],
  quickFilterValues: [""],
};

export default function History() {
  const navigation = useNavigate();
  const [rows, setRows] = React.useState<
    {
      analysisHistoryCount: number;
      buyer: string;
      buyerCombined: string;
      jobAddress: string;
      jobId: number;
      jobNumber: string;
      planDescription: string;
      planElevationDescription: string;
      planElevationNumber: string;
      planNumber: string;
      profitAnalysisId: number;
      profitAnalysisType: string;
      projectId: number;
      projectNumber: string;
      soldDate: string;
      startDate: string;
      subdivision: string;
      stageCode: string;
      stageDate: string;
      profitMargin: number;
      role: string;
    }[]
  >([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filt, setFilt] = React.useState<Filters>(defaultFilter);
  const [stage, setStage] = React.useState<string>("All");
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 50,
  });

  const columns: GridColDef[] = [
    {
      field: "jobAddress",
      headerName: "Address",
      minWidth: 260,
      flex: 1,
      filterable: true,
      renderCell: (params) => {
        const isCreateCase =
          params.row.profitAnalysisId === 0 &&
          params.row.role === "Write" &&
          (params?.row?.closeDate ?? null) === null;
        const isViewCase =
          (params.row.profitAnalysisId !== 0 &&
            (params?.row?.closeDate ?? null) !== null &&
            new Date(params.row.closeDate) > new Date()) ||
          params.row.role === "Read";
        const isReviseCase =
          params.row.profitAnalysisId !== 0 &&
          (params?.row?.closeDate ?? null) === null &&
          params.row.role === "Write";

        const tooltipTitle = isCreateCase
          ? "Create"
          : isReviseCase
          ? "Revise"
          : isViewCase
          ? "View"
          : "";

        const href = isCreateCase
          ? "#"
          : isReviseCase
          ? `/edit/${params.row.profitAnalysisId}`
          : isViewCase
          ? `/view/${params.row.profitAnalysisId}`
          : "#";

        const isClickable = isCreateCase || isReviseCase || isViewCase;

        return (
          <Tooltip title={tooltipTitle}>
            <Link
              color="inherit"
              sx={{
                cursor: isClickable ? "pointer" : "default",
                textDecoration: isClickable ? "underline" : "none",
              }}
              href={href}
              onClick={(e) => {
                if (isCreateCase) {
                  e.preventDefault();
                  navigation("/create", {
                    state: { ...params.row, method: "fromHist" },
                  });
                } else if (!isClickable) {
                  e.preventDefault();
                }
              }}
            >
              {params.value}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: "subdivision",
      headerName: "Subdivision",
      minWidth: 200,
      flex: 1,
      filterable: true,
    },
    {
      field: "jobNumber",
      headerName: "Job #",
      headerAlign: "left",
      align: "left",
      minWidth: 120,
      filterable: true,
      valueGetter: (params: any, row: any) => {
        if (!row?.projectNumber || !row?.jobNumber) {
          return null;
        }
        return `${row?.projectNumber}-${row?.jobNumber}`;
      },
      renderCell: (params) => <div>{params.value}</div>,
    },

    {
      field: "buyerCombined",
      headerName: "Buyer",
      minWidth: 380,
      filterable: true,
    },
    {
      field: "stageCode",
      headerName: "Stage",
      filterable: true,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "profitMargin",
      headerName: "Profit Projection %",
      filterable: true,
      minWidth: 140,
      align: "center",
      valueGetter: (params: any, row: any) => {
        if (!row?.profitMargin) {
          return null;
        }
        return `${row?.profitMargin}%`;
      },
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "projectedCloseDate",
      headerName: "Proj. Closed Date",
      minWidth: 200,
      valueFormatter: (params: any) => moment(params.value).format("L"),
      filterable: true,
      align: "center",
      renderCell: (params) => (
        <div>
          {params.row.profitAnalysisId === 0
            ? ""
            : moment(params.row.projectedCloseDate).format("L")}
        </div>
      ),
    },
    {
      field: "CloseDate",
      headerName: "Closed Date",
      minWidth: 200,
      valueFormatter: (params: any) => {
        if (!params?.row?.closeDate) return "";
        return moment(params.row.closeDate).format("L");
      },
      filterable: true,
      align: "center",
      renderCell: (params) => (
        <div>
          {params.row.profitAnalysisId === 0
            ? ""
            : moment(params.row.closeDate).format("L")}
        </div>
      ),
    },
    {
      field: "profitAnalysisId",
      headerName: "Actions",
      valueFormatter: (params: any) => {
        return params.value === 0 ? false : true;
      },
      align: "right",
      headerAlign: "right",
      minWidth: 140,
      renderCell: (params: any) => {
        return (
          <Stack
            direction="row"
            spacing={0.5}
            mr={2}
            sx={{
              justifyContent: "flex-end",
            }}
          >
            {((params.row.profitAnalysisId !== 0 &&
              (params?.row?.closeDate ?? null) !== null &&
              new Date(params.row.closeDate) > new Date()) ||
              params.row.role === "Read") && (
              <Tooltip
                placement="left"
                title={`View PA for ${params.row.jobAddress}`}
              >
                <IconButton href={`/view/${params.row.profitAnalysisId}`}>
                  <VisibilityIcon color="primary" />
                </IconButton>
              </Tooltip>
            )}
            {params.row.profitAnalysisId !== 0 &&
              (params?.row?.closeDate ?? null) === null &&
              params.row.role === "Write" && (
                <Tooltip
                  placement="left"
                  title={`Revise PA for ${params.row.jobAddress}`}
                >
                  <IconButton href={`/edit/${params.row.profitAnalysisId}`}>
                    <EditIcon color="primary" />
                  </IconButton>
                </Tooltip>
              )}
            {params.row.profitAnalysisId === 0 &&
              params.row.role === "Write" &&
              (params?.row?.closeDate ?? null) === null && (
                <Tooltip
                  placement="left"
                  title={`Create PA for ${params.row.jobAddress}`}
                >
                  <IconButton
                    color="primary"
                    onClick={() => {
                      navigation("/create", {
                        state: { ...params.row, method: "fromHist" },
                      });
                    }}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Tooltip>
              )}
          </Stack>
        );
      },
    },
  ];

  const loadJobs = () => {
    setLoading(true);
    axiosInstance
      .get(`/api/jobs/`)
      .then((res) => {
        const uniqueIds = {};

        // Check if res.data is an array
        const dataArray = Array.isArray(res?.data) ? res.data : [];

        // Use the filter method to filter out objects with unique IDs
        const uniqueObjects = dataArray.filter((obj: any) => {
          if (!uniqueIds[obj.jobId]) {
            uniqueIds[obj.jobId] = true;
            return true;
          }
          return false;
        });

        setRows(uniqueObjects as any);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const updateFilter = (field: string, value: string | string[]): void => {
    setFilt((prevFilt) => {
      // Filter out the existing filter item for the given field
      const otherItems = prevFilt.items.filter((item) => item.field !== field);

      // Determine the new filter item
      let newItems: {
        id: string;
        field: string;
        operator: string;
        value: string | string[];
      }[] = [];
      if (value !== "All") {
        newItems = [
          {
            id: `${field}-${Array.isArray(value) ? "multi" : value}`,
            field,
            operator: Array.isArray(value) ? "isAnyOf" : "equals",
            value,
          },
        ];
      }

      // Return the updated filter state
      return { ...prevFilt, items: [...otherItems, ...newItems] };
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFilt((prevFilt) => ({
      ...prevFilt,
      quickFilterValues: [newValue],
    }));
  };

  const handleStageChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setStage(newValue);
    updateFilter("stageCode", newValue);
  };

  React.useEffect(() => {
    loadJobs();
  }, []);

  return (
    <Container maxWidth={false} disableGutters>
      <Card sx={{ mt: 4, border: "1px solid #e5e7eb", borderRadius: 2 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 2,
              mb: 2,
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <TextField
                size="small"
                label="Search"
                variant="outlined"
                onChange={handleSearchChange}
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        color="action"
                        sx={{ color: (theme) => theme.palette.primary.main }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="stage-select-label">Stage</InputLabel>
                <Select
                  labelId="stage-select-label"
                  value={stage}
                  label="Stage"
                  size="small"
                  onChange={handleStageChange}
                >
                  <MenuItem value="All">All</MenuItem>
                  {[...Array(11)].map((_, i) => (
                    <MenuItem key={i} value={i.toString().padStart(2, "0")}>
                      {i.toString().padStart(2, "0")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Button
              disableElevation
              variant="contained"
              href="/create"
              startIcon={<AddIcon />}
            >
              Create PA
            </Button>
          </Box>
          <Box sx={{ height: "calc(100vh - 240px)", width: "100%" }}>
            <DataGridPro
              disableColumnSelector
              disableDensitySelector
              hideFooter
              getRowId={(row) => row.jobId}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              filterModel={filt}
              onFilterModelChange={(newFilterModel) =>
                setFilt(newFilterModel as Filters)
              }
              disableRowSelectionOnClick
              columns={columns}
              rows={rows}
              rowCount={rows.length}
              slots={{
                noRowsOverlay: NoRows,
              }}
              sx={{
                height: "100%",
                "&": {
                  border: "none",
                  // Header styling
                  "& .MuiDataGrid-columnHeaders": {
                    borderBottom: "1px solid #e5e7eb", // divide-gray-200
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: 600,
                      textTransform: "uppercase",
                      fontSize: "0.875rem", // text-xs
                      color: "#374151", // text-gray-700
                    },
                  },
                  // Row styling
                  "& .MuiDataGrid-row": {
                    backgroundColor: "white",
                    borderBottom: "1px solid #e5e7eb", // Add divider between rows
                    "&:hover": {
                      backgroundColor: "#f9fafb", // hover:bg-gray-50
                    },
                    "&:last-child": {
                      borderBottom: "none", // Remove border from last row
                    },
                  },
                  // Cell styling
                  "& .MuiDataGrid-cell": {
                    border: "none",
                    padding: "1rem", // px-4 py-4
                    whiteSpace: "nowrap",
                    color: "#374151", // text-gray-500
                  },
                  // Align treeview toggle
                  "& .MuiDataGrid-treeDataGroupingCellToggle": {
                    alignSelf: "auto",
                  },
                  // Remove default separators
                  "& .MuiDataGrid-iconSeparator": {
                    display: "none",
                  },
                  // Table body
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: "white",
                  },
                  // Pagination styling (if needed)
                  "& .MuiTablePagination-root": {
                    color: "#6b7280", // text-gray-500
                  },
                  // Remove focus outlines
                  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                    {
                      outline: "none",
                    },
                  "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                },
              }}
              loading={loading}
            />
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}
